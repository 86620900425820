import styled from "styled-components"
import { mouv, taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1;
    display : flex;
    flex-direction : column;
`;

interface Data {
}
export const BlockNom = styled.div<Data>`
    font-size : 50px;
`;

interface Data {
}
export const BlockDescriptif = styled.div<Data>`
    flex : 1;
    font-size : 20px;
`;

interface Data {
}
export const BlockProduits = styled.div<Data>`
    flex : 1;
    font-size : 20px;
`;

interface Data {
}
export const BlockBtn = styled.div<Data>`
    font-size : 20px;
`;