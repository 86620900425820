import styled from "styled-components"
import { mouv, taille } from "../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1;
    min-height : 100vh;
    min-width : ${taille.minBig};
    box-sizing: border-box;
    font-family: "Rajdhani", sans-serif;
    flex-direction: column;
    display : flex;
    padding-top : 20vh;
    padding-bottom : 20vh;
`;


interface Data {
}
export const Children = styled.div<Data>`
    flex : 1;
    display : flex;
    justify-content: center;
    align-items: center;
`;

interface Data {
}
export const Btn = styled.div<Data>`
    ${mouv}
    font-size : 3vw;
    border : 1px solid rgba(255,255,255,0.5);
    padding : 10px;
`;

interface Data {
}
export const Text = styled.div<Data>`
    ${mouv}
    font-size : 5vw;
    padding : 10px;
    color :  rgba(255,255,255,0.6);
`;