import Cookies from "js-cookie";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginGoogle } from "../hook/persons/useLoginGoogle";
import { UpdateFunctionContextToken } from "./tokenContext";
import axios from "axios";
import { toast } from "react-toastify";

export type BoissonFront = {
  id : number,
  nom : string,
  categorie : 'Cocktail' | 'shot' | 'Alcool_leger' | 'Soft',
  quantite : number,
  produits : EventProduitForFront[],
  descriptif : string,
  nbVerre : number,
  proprio: number | undefined
}

export type EventProduitForFront = {
  quantite : number,
  produit : Produit,
}

export type Produit = {
  id: number | undefined;  
  categorie: string;   
  mesure: string;    
  produit: string;    
  unite: number;      
  prix: string;  
}

type UpdateFunctionContextBoissonType = {
      setCategorie: React.Dispatch<React.SetStateAction<"Cocktail" | "Alcool_leger" | "Soft" | "shot" | undefined>>
      categorie: "Cocktail" | "Alcool_leger" | "Soft" | "shot" | undefined
      setSearch: React.Dispatch<React.SetStateAction<string | undefined>>
      boissons : BoissonFront[] | undefined
      search: string | undefined
      deleteBoisson: (idBoisson: number) => void,
      addBoisson : (newBoisson : BoissonFront) => Promise<void>
  };
  
  export const UpdateFunctionContextBoisson = createContext<UpdateFunctionContextBoissonType>({} as UpdateFunctionContextBoissonType); // Utilisez un cast pour éviter de fournir une valeur par défaut
  
  type UpdateFunctionProviderProps = {
    children: React.ReactNode;
  };
  export const UpdateFunctionProviderBoisson: FC<UpdateFunctionProviderProps> = ({ children }) => {
    const {token, updateToken} = useContext(UpdateFunctionContextToken);
    const [categorie, setCategorie] = useState<'Cocktail' | 'shot' | 'Soft'| 'Alcool_leger' | undefined>();
    const [search, setSearch] = useState<string | undefined>();
    const [boissons, setBoissons] = useState<BoissonFront[] | undefined>(undefined);
  
    function sortObjectsByProprio(array: BoissonFront[]): BoissonFront[] {
      return array.sort((a, b) => {
        if (a.proprio !== undefined && b.proprio === undefined) {
          return -1; // `a` avant `b`
        } else if (a.proprio === undefined && b.proprio !== undefined) {
          return 1;  // `b` avant `a`
        } else {
          return 0;  // ne change pas l'ordre si les deux sont définis ou indéfinis
        }
      });
    }

    function requestBoissonsByCategorie() {
      axios.get(`${process.env.REACT_APP_URL_BACK}/boissons?categorie=${categorie}${search ? `&search=${search}` : ''}`, {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
      .then((res) => {
        setBoissons(sortObjectsByProprio(res.data));
      }).catch((err) => {
        toast.error(err.response.data.message)
        updateToken(undefined)
      })
    }

    async function addBoisson(newBoisson : BoissonFront) {
      return axios.post(`${process.env.REACT_APP_URL_BACK}/boissons`, {newBoisson}, {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
      .then((res) => {
        requestBoissonsByCategorie();
      }).catch((err) => {
        toast.error(err.response.data.message)
        throw new Error();
      })
    }

    function deleteBoisson(idBoisson : number) {
      axios.delete(`${process.env.REACT_APP_URL_BACK}/boissons?idBoisson=${idBoisson}`, {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
      .then((res) => {
        requestBoissonsByCategorie();
        toast.success('Nouveau cocktail !')
      }).catch((err) => {
        toast.error(err.response.data.message)
        // updateToken(undefined)
      })
    }



    useEffect(() => {
        setCategorie('Cocktail');
    }, []);
 
    useEffect(() => {
      if (categorie && token) {
        requestBoissonsByCategorie();
      }
    }, [categorie, search, token]);


    return (
      <UpdateFunctionContextBoisson.Provider value={{
        boissons,
        setCategorie,
        setSearch,
        search,
        categorie,
        deleteBoisson,
        addBoisson
       }}>
        {children}
      </UpdateFunctionContextBoisson.Provider>
    );
  };
