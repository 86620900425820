import styled from "styled-components"
import { breakpoints, mouv } from "../../variable/styles";


interface Data {
}
export const Container = styled.div<Data>`
    height : 100%;
    width : 100vw;
    display : flex;
    color : rgba(255,255,255,0.5);
    transition : all 0.3s ease;
    background-color : #151C21;
    &:hover {
        color : rgba(255,255,255,0.7);
        background-color : rgba(255,255,255,0.1);
    }
`;

interface Data {
}
export const BtnMenu = styled.div<Data>`
    display : flex;
    justify-content: center;
    align-items: center;
    margin : 20px;
    ${mouv};
`;
interface Data {
}
export const BlockBtnGauche= styled.div<Data>`
    flex : 1;
    display : flex;
    justify-content: start;
    align-items: center;
`;

interface Data {
}
export const BlockBtnDroite = styled.div<Data>`
    display : flex;
    justify-content: start;
    align-items: center;
`;



// @media (max-width: ${breakpoints.sm}) {
//     display: flex;
//     position : fixed;
//     top : 20px;
//     left : 20px;
//     height : 40px;
//     width : 40px;
// }
