import styled from "styled-components"
import { taille } from "../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1; 
    min-width : ${taille.minBig};
    box-sizing: border-box;
    font-family: "Rajdhani", sans-serif;
    position : relative;
    display : flex;
    gap : 20px;
    color : rgba(255,255,255,0.5);
`;


interface Data {
}
export const BlockInfo = styled.div<Data>`
`;

interface Data {
}
export const BlockBoisson = styled.div<Data>`
width : ${taille.minBig};
display : flex;
`;

interface Data {
}
export const Titre = styled.div<Data>`
    font-size : 50px;
`;

interface Data {
}
export const Info = styled.div<Data>`
    font-size : 30px;
`;

interface Data {
}
export const BlockAgenda = styled.div<Data>`
flex : 1;
display : flex;
`;