import React from 'react';
import { Container, ImgBackground, NavBarStyle, PageStyle } from './styles';
import { ToastContainer } from 'react-toastify';
import { Navbar } from '../../../composant/navbar/navbar';

interface Data {
  children: React.ReactNode;  
};

export function Layout({children} : Data) {


  return (
    <Container>
      <ToastContainer />
      <ImgBackground src="logo.png" />
      <NavBarStyle>
        <Navbar/>
      </NavBarStyle>
      <PageStyle>
        {children}
      </PageStyle>
    </Container>
  );
}