import styled from "styled-components"
import { taille } from "../../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    width : ${taille.minSmall};
    background-color : rgba(255,255,255,0.1);
    box-sizing: border-box;
    font-size : 20px;
    color : rgba(255,255,255,0.5);
    padding : 20px;
`;

interface Data {
}
export const BlockBoissonsTitre = styled.div<Data>`
    font-size : 25px;
`;

interface Data {
}
export const BlockBoisson = styled.div<Data>`
    padding-left : 10px; 
    display : flex;
`;

interface Data {
}
export const BlockBoissonTitre = styled.div<Data>`
    padding-left : 10px;  
`;


