import { useContext, useEffect, useState } from 'react';
import { BtnAddEvent, BtnEvent, Container, BlockDate, Nom, BlockStatus, SmalNav, BtnSmalNav } from './styles';
import { log } from '../../../lib/logger/config';
import { EventForPersonFront, PersonFront, useRequestPerson } from '../../../hook/persons/usePerson';
import { TextBtn } from '../../../composant/btn/textBtn/textBtn';
import { Titre } from '../styles';
import { useNavigate } from 'react-router-dom';
import { EventFront } from '../../../context/eventContext';

interface Data {
  $events : EventForPersonFront[]
  $addEvent : () => void;
}
export function TableauEvent({$events, $addEvent} : Data) {
  const navigate = useNavigate();
  const [witchEvent, setWitchEvent] = useState<boolean>(true);
  const [events, setEvent] = useState<EventForPersonFront[]>($events);

  useEffect(() => {
    if ($events) {
      if (witchEvent === false) {
        setEvent($events.filter((event) => new Date(event.dateFin).getTime() <= new Date(new Date().setHours(1,0,0,0)).getTime()))
      } else {
        setEvent($events.filter((event) => new Date(event.dateFin).getTime() >= new Date(new Date().setHours(1,0,0,0)).getTime()))
      }

    }
  }, [$events, witchEvent])


  return (
    <Container>
      <Titre>Mes events</Titre>
     <BtnAddEvent>
      <TextBtn $active={true} $text='Nouvel événement' $onClick={() => {$addEvent()}}/>
     </BtnAddEvent>
     <SmalNav>
      <BtnSmalNav $active={witchEvent === true}>
        <TextBtn $active={witchEvent === false} $text='En cours' $onClick={() => {setWitchEvent(true)}}/>
      </BtnSmalNav>
      <BtnSmalNav $active={witchEvent === false}>
        <TextBtn $active={witchEvent === true} $text='Terminé' $onClick={() => {setWitchEvent(false)}}/>
      </BtnSmalNav>
     </SmalNav>
     {events.map((event, index) => {
      return (
        <BtnEvent key={index} onClick={() => {navigate(`/Event?id_event=${event.id}`)}}>
          <Nom>{event.nom}</Nom>
          <BlockDate>{`${new Date(event.dateDebut).getDate()}/${new Date(event.dateDebut).getMonth()} -> ${new Date(event.dateFin).getDate()}/${new Date(event.dateFin).getMonth()}`}</BlockDate>
          <BlockStatus $status={new Date(event.dateFin).getTime() > ((new Date()).getTime()) ? 'en cours' : 'fini'}>{new Date(event.dateFin).getTime() > ((new Date()).getTime()) ? 'en cours' : 'fini'}</BlockStatus>
        </BtnEvent>
      )
     })}
    </Container>
  );
}