import styled from "styled-components"
import { color, mouv, taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1;
    display : flex;
    flex-direction : column;
    font-size : 20px;
`;

interface Data {
}
export const BlockNom = styled.div<Data>`
    font-size : 50px;
`;

interface Data {
}
export const BlockPrincipale = styled.div<Data>`
    flex : 1;
    display : flex;
    flex-wrap: wrap;
`;

interface Data {
}
export const BlockProduits = styled.div<Data>`
    display : flex;
    flex-direction : column;
    max-height : 750px;
    margin: auto;

`;


interface Data {
}
export const BlockListProduit = styled.div<Data>`
    flex : 1;
    overflow-y: auto;
    display : flex;
    flex-direction : column;
    gap : 10px;
    min-height : 550px;
`;

interface Data {
}
export const BlockProduitsSearch = styled.div<Data>`
    

`;

interface Data {
}
export const BlockProduitsProduit = styled.div<Data>`
     display : flex;
    justify-content: center;
    align-items: center;
    padding : 5px;


`;

interface Data {
}
export const BlockBoisson = styled.div<Data>`
    flex : 1;
    display : flex;
    flex-direction : column;
    padding : 20px;
    gap : 50px;
`;

interface Data {
}
export const BlockBoissonNom = styled.div<Data>`

`;

interface Data {
}
export const BlockBoissonDescriptif = styled.div<Data>`

`;

interface Data {
}
export const BlockBoissonCategories = styled.div<Data>`
    display : flex;

`;

interface DataBlockBoissonCategorie {
    $active : boolean
}
export const BlockBoissonCategorie = styled.div<DataBlockBoissonCategorie>`
    flex : 1;
    display : flex;
    justify-content: center;
    align-items: center;
    padding : 5px;
    font-size : 20px;
    ${mouv}
    ${({$active}) => $active && `background-color : rgba(255,255,255,0.1)`};

`;

interface Data {
}
export const BlockBoissonProduits = styled.div<Data>`
    overflow-y: auto;
    max-height : 200px;
    border : 1px solid ${color.text};
    padding : 20px;
`;


interface Data {
}
export const BlockBoissonProduit = styled.div<Data>`
    display : flex;
`;

interface Data {
}
export const BlockBoissonProduitChildren = styled.div<Data>`
    flex : 1;
    display : flex;
    justify-content: center;
    align-items: center;
`;



interface Data {
}
export const BlockBtn = styled.div<Data>`
    font-size : 20px;
`;