import { useContext } from 'react';
import {BlockBtn, BlockDescriptif, BlockNom, BlockProduits, Container} from './styles';
import { BoissonFront, UpdateFunctionContextBoisson } from '../../../context/boissonContext';
import { EventFront, UpdateFunctionContextEvent } from '../../../context/eventContext';
import { TextBtn } from '../../btn/textBtn/textBtn';

interface Data {
  $boisson : BoissonFront | undefined
  $close : () => void
  $event ?: EventFront
  $addBoisson ?: (id_boisson: number) => void;
  $supBoisson ?: (id_boisson: number) => void;
}
export function PopupBoisson({$boisson, $close, $event, $addBoisson, $supBoisson} : Data) {
  const {deleteBoisson} = useContext(UpdateFunctionContextBoisson);

  let ifUse = undefined;
  if ($event) {
    ifUse = $event?.boissons.map((b : any) => b.id).includes($boisson?.id) || false;
  }
  if ($boisson === undefined)
      return <></>
  return (
    <Container>
      <BlockNom>{$boisson.nom}</BlockNom>
      <BlockProduits>{
        $boisson.produits.map((produit, index) => {
          return (<div key={index}>{produit.produit.produit} {produit.quantite} {produit.produit.mesure}</div>)
        })
        }</BlockProduits>
      <BlockDescriptif>{$boisson.descriptif}</BlockDescriptif>
      <BlockBtn>
        {ifUse === undefined && <TextBtn $active={true} $text='Suprimer cette boisson de votre list' $onClick={() => {deleteBoisson($boisson.id); $close()}}/>}
        {ifUse === false && <TextBtn $active={true} $text='Ajouter cette boisson a l event' $onClick={() => {$addBoisson && $addBoisson($boisson.id); $close()}}/>}
        {ifUse === true && <TextBtn $active={true} $text='Suprimer cette boisson de l event' $onClick={() => {$supBoisson && $supBoisson($boisson.id); $close()}}/>}
      </BlockBtn>
    </Container>
  );
}