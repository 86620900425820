import Cookies from "js-cookie";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginGoogle } from "../hook/persons/useLoginGoogle";
import { UpdateFunctionContextToken } from "./tokenContext";
import axios from "axios";
import { toast } from "react-toastify";

export type EventFront = {
  id: number | undefined,  
  nom: string,  
  persons: string[],
  boissons: {id:number, nom:string,categorie:string,quantite:number,produits:{produit:any, quantite:number}[],descriptif:string}[],
  dateDebut: number,
  dateFin: number,
  nb_cocktail_pers_jour: number,
  nb_shot_pers_jour: number,
  nb_alcool_leger_pers_jour: number,
  nb_soft_pers_jour: number,
  nbJour : number,
}

type UpdateFunctionContextEventType = {
  requestEvent: () => void
  deleteEvent: () => void
  supPerson: (person: string) => void
  addPerson: (person: string) => Promise<void>
  addBoisson: (id_boisson: number) => void
  supBoisson : (id_boisson : number) => void
  updateInfoEvent : () => void
  setEvent: React.Dispatch<React.SetStateAction<EventFront | undefined>>
  event : EventFront | undefined
  };
  
  export const UpdateFunctionContextEvent = createContext<UpdateFunctionContextEventType>({} as UpdateFunctionContextEventType); // Utilisez un cast pour éviter de fournir une valeur par défaut
  
  type UpdateFunctionProviderProps = {
    children: React.ReactNode;
  };
  export const UpdateFunctionProviderEvent: FC<UpdateFunctionProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id_event = searchParams.get('id_event');
    const {token, updateToken} = useContext(UpdateFunctionContextToken);
    const [event, setEvent] = useState<EventFront | undefined>();
  

    function updateInfoEvent() {
      axios.patch(`${process.env.REACT_APP_URL_BACK}/events`, {event:{...event,boissons:event?.boissons.map((boissons) => boissons.id)}} ,{
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
        .then((res) => {
          setEvent(res.data);
          toast.success('Information mise a jours')
        }).catch((err) => {
        toast.error(err.response.data.message)
        // setEvent(undefined)
        // updateToken(undefined)
      })
    }

    function addBoisson(id_boisson: number) {
      axios.post(`${process.env.REACT_APP_URL_BACK}/events/boissons`, {id_boisson : id_boisson, id_event:event?.id} ,{
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
        .then((res) => {
          setEvent(res.data);
        }).catch((err) => {
        toast.error(err.response.data.message)
        // setEvent(undefined)
        // updateToken(undefined)
      })
    }

    function supBoisson(id_boisson: number) {
      axios.delete(`${process.env.REACT_APP_URL_BACK}/events/boissons?id_boisson=${id_boisson}&id_event=${id_event}` , {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
        .then((res) => {
          setEvent(res.data);
        }).catch((err) => {
        toast.error(err.response.data.message)
        // setEvent(undefined)
        // updateToken(undefined)
      })
    }

    async function addPerson(person: string) {
      return axios.post(`${process.env.REACT_APP_URL_BACK}/events/persons`, {participants : person, id_event:event?.id} ,{
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
        .then((res) => {
          setEvent(res.data);
        }).catch((err) => {
          toast.error(err.response.data.message)
          throw new Error();
      })
    }
  
    function supPerson(person: string) {
      axios.delete(`${process.env.REACT_APP_URL_BACK}/events/persons?participant=${person}&id_event=${id_event}`, {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
      .then((res) => {
        setEvent(res.data);
      }).catch((err) => {
        toast.error(err.response.data.message)
        setEvent(undefined)
        updateToken(undefined)
      })
    }
  
    function requestEvent() {
      axios.get(`${process.env.REACT_APP_URL_BACK}/events?id_event=${id_event}`, {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
      .then((res) => {
        setEvent(res.data);
      }).catch((err) => {
        navigate('/Profile')
      })
    }

    function deleteEvent() {
      axios.delete(`${process.env.REACT_APP_URL_BACK}/events?id_event=${id_event}`, {
        headers: {
          Authorization: `Bearer ${token}`  // Envoi du token dans le header
        }
      })
      .then((res) => {
        requestEvent();
        toast.info('Event suprimer avec sucesse')
      }).catch((err) => {
        toast.error(err.response.data.message)
        updateToken(undefined)
      })
    }

    useEffect(() => {
      if (id_event && token) {
        requestEvent();
      }   
    }, [id_event, token]);

    return (
      <UpdateFunctionContextEvent.Provider value={{
        setEvent,
        requestEvent,
        supPerson,
        addPerson,
        addBoisson,
        supBoisson,
        updateInfoEvent,
        deleteEvent,
        event,
       }}>
        {children}
      </UpdateFunctionContextEvent.Provider>
    );
  };
