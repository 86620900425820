import { Container } from './styles';

interface Data {
  $date : number,
  $setDate : (date : number) => void,
};
export function InputDate({ $date, $setDate} : Data) {
  let date = new Date($date)

  
  return (
    <Container type='date' onChange={(e) => {$setDate(new Date(e.target.value).getTime())}} value={new Date(date).toISOString().split('T')[0]}>
    </Container>
  );
}