import styled from "styled-components"
import { taille } from "../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1;
    min-width : ${taille.minBig};
    box-sizing: border-box;
    font-family: "Rajdhani", sans-serif;
    display : flex;
    font-size : 50px;
`;


