import styled from "styled-components"
import { breakpoints, mouv, taille } from "../../variable/styles";


interface DataContainer {
    $active : boolean
}
export const Container = styled.div<DataContainer>`
    ${({$active}) => $active === false && `display : none;`}
    ${({$active}) => $active === true && `display : flex;`}
    position : fixed;
    top: 0px;
    left: 0px;
    width : 100vw;
    min-height : 100vh;
    min-width : ${taille.minBig};
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    z-index : 3;
`;

interface Data {
}
export const Children = styled.div<Data>`
    width : 50vw;
    min-height : 70vh;
    min-width : ${taille.minSmall};
    background-color : rgba(255,255,255,0.1);
    border-radius : 10px;
    padding : 10px;
    box-sizing: border-box;
    color : rgba(255,255,255,0.5);
    display : flex;
    height : 90vh;
    overflow-y: auto;
`;

interface Data {
}
export const BlockIcon = styled.div<Data>`
    position : absolute;
    top : 10px;
    right : 10px;
    width : 50px;
    height : 50px;
    font-size : 40px;
`;
