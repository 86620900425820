import React from 'react';
import { Container } from './styles';

interface Data {
  $text : string;
  $onClick : () => void;
  $active : boolean
};
export function TextBtn({$text, $onClick, $active} : Data) {
  return (
    <Container $active={$active} onClick={() => {$active === true && $onClick()}}>
      {$text}
    </Container>
  );
}