import styled from "styled-components"
import { mouv, taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    width : ${taille.minSmall};
    background-color : rgba(255,255,255,0.1);
    padding : 10px;
`;

interface Data {
}
export const Titre = styled.div<Data>`
    font-size : 30px;
`;


interface Data {
}
export const BtnAddEvent = styled.div<Data>`
    bottom : 20px;
    left : 0px;
    font-size : 20px;    
    display : flex;
    justify-content: center;
    align-items: center;
`;

interface Data {
}
export const BtnEvent = styled.div<Data>`
    width : 100%;
    color : white;
    padding-top : 10px;
    padding-bottom : 10px;
    ${mouv}
    position : relative;
    &:hover {
        background-color : rgba(0,0,0,0.1);
    }
`;

interface DataStatus {
    $status : string
}
export const BlockStatus = styled.div<DataStatus>`
    position : absolute;
    right : 0px;
    bottom : 0px;
    color : white;
    font-size : 15px;
    ${({$status}) => $status === 'en cours' && 'color:green;'}
`;

interface Data {
}
export const BlockDate = styled.div<Data>`
    position : absolute;
    left : 0px;
    bottom : 0px;
    color : white;
    font-size : 12px;
`;

interface Data {
}
export const Nom = styled.div<Data>`
    color : white;
    font-size : 20px;
`;

interface Data {
}
export const SmalNav = styled.div<Data>`
    display : flex;
`;

interface DataBtnSmalNav {
    $active : boolean
}
export const BtnSmalNav = styled.div<DataBtnSmalNav>`
    flex : 1;
    display : flex;
    justify-content: center;
    align-items: center;
    ${({$active}) => $active && `background-color : rgba(255,255,255,0.1)`};
`;