import styled from "styled-components"
import { taille } from "../../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    width : ${taille.minSmall};
    background-color : rgba(255,255,255,0.1);
    box-sizing: border-box;
    font-size : 20px;
    color : rgba(255,255,255,0.5);
    padding : 20px;
    display : flex;
    flex-direction : column;
`;

interface Data {
}
export const BlockProduit = styled.div<Data>`
    
`;

interface Data {
}
export const BlockPrix = styled.div<Data>`
    align-self: flex-end;
    margin-top : 20px;
`;


interface Data {
}
export const BlockPrixTotale = styled.div<Data>`
    
`;

interface Data {
}
export const BlockPrixPers = styled.div<Data>`
    
`;