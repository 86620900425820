import { useContext, useState } from 'react';
import { IconBtn } from '../../../composant/btn/iconBtn/iconBtn';
import { TextBtn } from '../../../composant/btn/textBtn/textBtn';
import { Container, BlockNav, BtnNav, BlockContenue } from './styles';
import { MenuBoisson } from '../../../composant/menuBoisson/menuBoisson';
import { UpdateFunctionContextEvent } from '../../../context/eventContext';
import { TableauEvent } from '../tableauEvent/tableauEvent';
import { useRequestPerson } from '../../../hook/persons/usePerson';

interface Data {
}
export function BlockMenu({} : Data) {
  const [witchPage, setWitchPage] = useState('Mes events');
  const {person, requestNewEvent} = useRequestPerson()

  if (!person)
    return <></>
  return (
    <Container>
      <BlockNav>
          <BtnNav $active={witchPage === 'Mes events'}>
            <TextBtn $active={true} $text='Mes events' $onClick={() => {setWitchPage('Mes events')}}/>
          </BtnNav>
          <BtnNav $active={witchPage === 'Mes boissons'}>
            <TextBtn $active={true} $text='Mes boissons' $onClick={() => {setWitchPage('Mes boissons')}}/>
          </BtnNav>
      </BlockNav>
      {witchPage === 'Mes events' && <BlockContenue><TableauEvent $events={person.events} $addEvent={requestNewEvent}/></BlockContenue>}
      {witchPage === 'Mes boissons' && <BlockContenue><MenuBoisson/></BlockContenue>}
    </Container>
  );
}