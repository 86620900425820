import { log } from '../../lib/logger/config';
import { ComposantRoutage } from '../../router/router';


export function App() {

  log.page('App');
  return (
        <ComposantRoutage/>
  );
}

