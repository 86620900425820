import Cookies from "js-cookie";
import { FC, createContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginGoogle } from "../hook/persons/useLoginGoogle";


type UpdateFunctionContextTokenType = {
  updateToken: (newToken : string | undefined) => void
  token : string | undefined
  };
  
  export const UpdateFunctionContextToken = createContext<UpdateFunctionContextTokenType>({} as UpdateFunctionContextTokenType); // Utilisez un cast pour éviter de fournir une valeur par défaut
  
  type UpdateFunctionProviderProps = {
    children: React.ReactNode;
  };
  export const UpdateFunctionProviderToken: FC<UpdateFunctionProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    function updateToken(newToken : string | undefined) {
      setToken(newToken);
      if (newToken === undefined) {
        Cookies.remove('token');
        navigate('/Accueil');
      }
      else {
        Cookies.set('token', newToken);
      }
    }

    useEffect(() => {
      updateToken(Cookies.get('token'))
    }, [])

    return (
      <UpdateFunctionContextToken.Provider value={{
        updateToken,
        token,
       }}>
        {children}
      </UpdateFunctionContextToken.Provider>
    );
  };
