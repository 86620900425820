import kleur from 'kleur';

function stringifyOrConvertToJSON(input: any): string {
    if (typeof input === 'string') {
        return input; // Si c'est déjà une chaîne de caractères, retourne l'entrée telle quelle
    } else {
        try {
            return JSON.stringify(input); // Convertit l'objet en une chaîne JSON
        } catch (error) {
            console.error('Erreur lors de la conversion en JSON :', error);
            return ''; // En cas d'erreur, retourne une chaîne vide
        }
    }
}

const valuePrint = ["err","page","info"];

export const log = {
    err : (...arg : any) => {
        if (valuePrint.includes("err"))
            console.log(kleur.red(stringifyOrConvertToJSON(`[ERR] : ` + arg)));
    },
    page : (...arg : any) => {
        if (valuePrint.includes("page"))
            console.log(kleur.yellow(stringifyOrConvertToJSON(`[PAGE] : ` + arg)));
    },
    info : (...arg : any) => {
        if (valuePrint.includes("info"))
            console.log(stringifyOrConvertToJSON(`[INFO] : ` + arg)); 
    }
}
