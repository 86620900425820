import { Container } from './styles';

interface Data {
  $placeholder : string,
  $text : string,
  $setText : (text : string) => void,
};
export function InputText({$placeholder, $text, $setText} : Data) {

  return (
    <Container placeholder={$placeholder} onChange={(e) => {$setText(e.target.value);}} value={$text} wrap="soft">
    </Container>
  );
}