import { FC, createContext, useEffect } from "react";


type UpdateFunctionContextGlobaleType = {
  };
  
  export const UpdateFunctionContextGlobale = createContext<UpdateFunctionContextGlobaleType>({} as UpdateFunctionContextGlobaleType); // Utilisez un cast pour éviter de fournir une valeur par défaut
  
  type UpdateFunctionProviderProps = {
    children: React.ReactNode;
  };
  export const UpdateFunctionProviderGlobale: FC<UpdateFunctionProviderProps> = ({ children }) => {


    useEffect(() => {
     
    }, [])

    return (
      <UpdateFunctionContextGlobale.Provider value={{
       }}>
        {children}
      </UpdateFunctionContextGlobale.Provider>
    );
  };
