import React, { useContext, useEffect, useRef } from 'react';
import { Btn, Children, Container, Text } from './styles';
import { log } from '../../lib/logger/config';
import { TextBtn } from '../../composant/btn/textBtn/textBtn';
import { useLoginGoogle } from '../../hook/persons/useLoginGoogle';
import { UpdateFunctionContextToken } from '../../context/tokenContext';
import { useNavigate } from 'react-router-dom';

interface Data {
}
export function Accueil() {
  const {token, updateToken} = useContext(UpdateFunctionContextToken);
  const {requestLoginGoogle, tokenTmp} = useLoginGoogle();
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenTmp) {
      updateToken(tokenTmp);
      navigate('/Profile');
    } 
  }, [navigate, tokenTmp])

  log.page('Accueil');
  return (
    <Container>
      <Children>
        <Text>
          Bienvenue à Champagne
        </Text>
      </Children>
      <Children>
        <Btn>
          <TextBtn $text={'Connection avec google !'} $active={true} $onClick={() => {requestLoginGoogle()}}/>
        </Btn>
      </Children>
      
    </Container>
  );
}