import { useContext, useState } from 'react';
import { IconBtn } from '../../../composant/btn/iconBtn/iconBtn';
import { TextBtn } from '../../../composant/btn/textBtn/textBtn';
import { Container, BlockNav, BtnNav, BlockData } from './styles';
import { UpdateFunctionContextEvent } from '../../../context/eventContext';
import { ListBoisson } from './listBoisson/listBoisson';
import { ListCourse } from './listCourse/listCourse';



interface Data {
}
export function BlockInfo({} : Data) {
  const {event, addPerson, deleteEvent} = useContext(UpdateFunctionContextEvent);

  if (!event) {
    return <></>
  }
  return (
    <Container>
      <BlockNav>
          <BtnNav>
            {event.nom}
          </BtnNav>
          <BtnNav>
            <IconBtn $nameIcon='Delete' $active={true} $onClick={() => {deleteEvent()}}/>
          </BtnNav>
      </BlockNav>
      <BlockData>
        <ListCourse/>
        <ListBoisson/>
      </BlockData>
    </Container>
  );
}