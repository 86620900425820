import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UpdateFunctionContextToken } from '../../context/tokenContext';
import { Produit } from '../../context/boissonContext';

// export type Produit = {

// }

export function useProduit() {
  const {token, updateToken} = useContext(UpdateFunctionContextToken);
  const [produits, setProduits] = useState<Produit[]>([]);
  const [search, setSearch] = useState<string>('');

  function requestProduits() {
    axios.get(`${process.env.REACT_APP_URL_BACK}/produits?search=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`  // Envoi du token dans le header
      }
    })
    .then((res) => {
      setProduits(res.data);
    }).catch((err) => {
      toast.error(err.response.data.message)
      // updateToken(undefined)
    })
  }

  useEffect(() => {
    if (token)
      requestProduits();
     
  }, [token, search]);

  return {produits,search, setSearch };
};