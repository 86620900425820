import { useContext, useEffect, useState } from 'react';
import { BlockBtnAdd, BlockBtnAddPerson, BlockBtnCategorieBoisson, BlockParticipant, BlockParticipantBtn, BlockParticipantNom, BlockParticipants, BlockSearch, Container, Titre } from './styles';
import { EventFront, UpdateFunctionContextEvent } from '../../context/eventContext';
import { BoissonFront, UpdateFunctionContextBoisson } from '../../context/boissonContext';
import { InputSmallText } from '../input/inputSmallText/inputSmallText';
import { TextBtn } from '../btn/textBtn/textBtn';
import { IconBtn } from '../btn/iconBtn/iconBtn';
import { PopUp } from '../popUp/popUp';
import { PopupBoisson } from './popupBoisson/popupBoisson';
import { PopupAddBoisson } from './popupAddBoisson/popupAddBoisson';


interface Data {
  $event?: EventFront
  $addBoisson ?: (id_boisson: number) => void;
  $supBoisson ?: (id_boisson: number) => void;

}
export function MenuBoisson({$event, $addBoisson, $supBoisson} : Data) {
  const {boissons, setCategorie, setSearch, search, categorie, addBoisson} = useContext(UpdateFunctionContextBoisson);
  const [popup, setPopup] = useState<undefined | BoissonFront>();
  const [ifAddBoisson, setIfAddBoisson] = useState<boolean>(false);

  if (!boissons) {
    return <></>
  }
  return (
    <Container>    
      <PopUp $close={popup === undefined ? false : true} $setClose={() => {setPopup(undefined)}}><PopupBoisson $boisson={popup} $close={() => {setPopup(undefined)}} $event={$event} $addBoisson={$addBoisson} $supBoisson={$supBoisson}/></PopUp>
      <PopUp $close={ifAddBoisson} $setClose={() => {setIfAddBoisson(false)}}><PopupAddBoisson $addBoisson={async (newBoisson : BoissonFront) => {return addBoisson(newBoisson).then(() => {setIfAddBoisson(false)}).catch(() => {throw new Error()})}} /></PopUp>
      <Titre>Mes boissons</Titre>
      <BlockSearch>
        <InputSmallText $placeholder='Nom' $setText={setSearch} $text={search || ''}/>
      </BlockSearch>
      {!search && <BlockBtnAddPerson>
        <BlockBtnCategorieBoisson $active={categorie === 'Cocktail'}>
          <TextBtn $active={true} $text='Cocktail' $onClick={() => {setCategorie('Cocktail')}}/>
        </BlockBtnCategorieBoisson>
        <BlockBtnCategorieBoisson $active={categorie === 'Alcool_leger'}>
          <TextBtn $active={true} $text='Alcool léger' $onClick={() => {setCategorie('Alcool_leger')}}/>
        </BlockBtnCategorieBoisson>
        <BlockBtnCategorieBoisson $active={categorie === 'shot'}>
          <TextBtn $active={true} $text='shot' $onClick={() => {setCategorie('shot')}}/>
        </BlockBtnCategorieBoisson>
        <BlockBtnCategorieBoisson $active={categorie === 'Soft'}>
          <TextBtn $active={true} $text='soft' $onClick={() => {setCategorie('Soft')}}/>
        </BlockBtnCategorieBoisson>

      </BlockBtnAddPerson>}
      <BlockParticipants>
        {boissons.map((boisson : any, index : number) => {
          let ifUse = $event?.boissons.map((b : any) => b.id).includes(boisson.id) || false;
          return (
            <BlockParticipant key={index}>
              <BlockParticipantNom $active={ifUse}>
                <TextBtn $text={boisson.proprio !== undefined ? `Perso : ${boisson.nom}` : boisson.nom } $active={true} $onClick={() => {setPopup(boisson)}}/>
              </BlockParticipantNom>
            </BlockParticipant>
          )
        })}
      </BlockParticipants>
      <BlockBtnAdd>
        <IconBtn $active={true} $nameIcon='Add_circle' $onClick={() => {setIfAddBoisson(true)}}/>  
      </BlockBtnAdd>  
    </Container>
  );
}