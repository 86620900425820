import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom";
import { Accueil } from "../page/accueil/accueil";
import { ComposantContexts } from "../context/composantContexts";
import { Profile } from "../page/profile/profile";
import { Event } from "../page/event/event";
import { Layout } from "../page/app/layout/layout";

export function ComposantRoutage() {

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <ComposantContexts>
                    <Layout>
                        <Outlet />
                    </Layout>
                </ComposantContexts>
            ),
            children: [
                {
                    path: '',
                    element: <Navigate to="/Accueil" replace />,
                },
                {
                    path: 'Accueil',
                    element: <Accueil />,
                },
                {
                    path: 'Profile',
                    element: <Profile />,
                },
                {
                    path: 'Event',
                    element: <Event />,
                },
            ],
        },
    ]);
    



    return <RouterProvider router={router} />;
}