import styled from "styled-components"
import { taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    height : 100%;
    box-sizing: border-box;
    display : flex;
    flex-direction: column;
`;

interface Data {
}
export const BlockNav = styled.div<Data>`
    box-sizing: border-box;
    display : flex;
    font-size : 20px;
`;

interface Data {
}
export const BlockContenue = styled.div<Data>`
    flex : 1;
    height : 700px;
    display : flex;
`;

interface DataBtnNav {
    $active : boolean
}
export const BtnNav = styled.div<DataBtnNav>`
    box-sizing: border-box;
    padding-left : 10px;
    padding-right : 10px;
    ${({$active}) => $active === true && `background-color : rgba(255,255,255,0.1)`};
`;
