import { useContext, useState } from 'react';
import {BlockBoisson, BlockBoissonCategorie, BlockBoissonCategories, BlockBoissonDescriptif, BlockBoissonNom, BlockBoissonProduit, BlockBoissonProduitChildren, BlockBoissonProduits, BlockBtn, BlockListProduit, BlockNom, BlockPrincipale, BlockProduits, BlockProduitsProduit, BlockProduitsSearch, Container} from './styles';
import { BoissonFront, Produit, UpdateFunctionContextBoisson } from '../../../context/boissonContext';
import { EventFront, UpdateFunctionContextEvent } from '../../../context/eventContext';
import { TextBtn } from '../../btn/textBtn/textBtn';
import { InputSmallText } from '../../input/inputSmallText/inputSmallText';
import { InputText } from '../../input/inputText/inputText';
import { IconBtn } from '../../btn/iconBtn/iconBtn';
import { useProduit } from '../../../hook/produits/useProduits';
import { InputNumber } from '../../input/inputNumber/inputNumber';

interface Data {
  $addBoisson : (newBoisson : BoissonFront) => Promise<void>
}
export function PopupAddBoisson({$addBoisson} : Data) {
  const [newBoisson, setNewBoisson] = useState<BoissonFront>({id:-1, nom:'', categorie:'Cocktail', produits:[], descriptif:'', quantite:0, nbVerre:0, proprio:undefined});
  const {produits, search, setSearch} = useProduit();

  function capitalizeFirstLetter(str : string) {
    if (!str) return ''; // Vérifie si la chaîne est vide
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Container>
      <BlockNom>Nouvelle boisson ?</BlockNom>
      <BlockPrincipale>
        <BlockBoisson>
          <BlockBoissonNom>
            <InputSmallText $placeholder='Nom du produit' $setText={(text) => {setNewBoisson({...newBoisson,nom:capitalizeFirstLetter(text)})}} $text={newBoisson.nom}/>
          </BlockBoissonNom>
          <BlockBoissonCategories>
            <BlockBoissonCategorie $active={newBoisson.categorie === 'Cocktail'} onClick={() => {setNewBoisson({...newBoisson,categorie:'Cocktail'})}}>Cocktail</BlockBoissonCategorie>
            <BlockBoissonCategorie $active={newBoisson.categorie === 'Alcool_leger'} onClick={() => {setNewBoisson({...newBoisson,categorie:'Alcool_leger'})}}>Alcool léger</BlockBoissonCategorie>
            <BlockBoissonCategorie $active={newBoisson.categorie === 'shot'} onClick={() => {setNewBoisson({...newBoisson,categorie:'shot'})}}>shot</BlockBoissonCategorie>
            <BlockBoissonCategorie $active={newBoisson.categorie === 'Soft'} onClick={() => {setNewBoisson({...newBoisson,categorie:'Soft'})}}>Soft</BlockBoissonCategorie>
          </BlockBoissonCategories>
          <BlockBoissonProduits>
            {newBoisson.produits.length <= 0 && `Choisissez des produit !`}
            {newBoisson.produits.map((produit, index) => {
              return (
                <BlockBoissonProduit key={index}>
                  <BlockBoissonProduitChildren>{produit.produit.produit}</BlockBoissonProduitChildren>
                  <BlockBoissonProduitChildren>
                    <InputNumber $number={produit.quantite} $setText={(nb) => {
                      setNewBoisson({...newBoisson,
                        produits:newBoisson.produits.map((pr) => {
                          if (pr.produit.id === produit.produit.id) { 
                            return {produit:produit.produit,quantite:nb} 
                          } else { 
                            return pr}
                          })})
                    }}/>
                    {produit.produit.mesure}
                  </BlockBoissonProduitChildren>
                  <BlockBoissonProduitChildren>
                    <IconBtn $active={true} $nameIcon='delete' $onClick={() => {setNewBoisson({...newBoisson,
                        produits:newBoisson.produits.filter((pr) => pr.produit.id !== produit.produit.id)})}}/>
                  </BlockBoissonProduitChildren>
                </BlockBoissonProduit>
              )
            })}



          </BlockBoissonProduits>
          <BlockBoissonDescriptif>
            <InputText $placeholder='Description' $setText={(text: string) => {setNewBoisson({...newBoisson,descriptif:text})}} $text={newBoisson.descriptif}/>
          </BlockBoissonDescriptif>
        </BlockBoisson>
        <BlockProduits>
          <div>Choisissez vos produit</div>
          <BlockProduitsSearch>
            <InputSmallText $placeholder='Chercher un produit' $setText={(text) => {setSearch(text)}} $text={search}/>
          </BlockProduitsSearch>
          <BlockListProduit>
            {produits.map((produit, index) => {
              return (
              <BlockProduitsProduit key={index}>
                <TextBtn $active={true} $onClick={() => {(newBoisson.produits.map((pro) => pro.produit)).includes(produit) ? 
                  setNewBoisson({...newBoisson, produits:newBoisson.produits.filter((pr) => pr.produit.id !== produit.id)}) : 
                  setNewBoisson({...newBoisson, produits:[...newBoisson.produits, {produit:produit, quantite:0}]})}} $text={produit?.produit}/>
              </BlockProduitsProduit>
              )
            })}
          </BlockListProduit>
        </BlockProduits>
      </BlockPrincipale>
      <BlockBtn>
        <TextBtn $active={true} $text='On valide ?' $onClick={() => {$addBoisson(newBoisson).then(() => {setNewBoisson({id:-1, nom:'', categorie:'Cocktail', produits:[], descriptif:'', quantite:0, nbVerre:0, proprio:undefined})}).catch(() => {})}}/>
      </BlockBtn>
    </Container>
  );
}