import { Container } from './styles';

interface Data {
  $placeholder : string,
  $text : string,
  $setText : (text : string) => void,
};
export function InputSmallText({$placeholder, $text, $setText} : Data) {

  return (
    <Container type='text' placeholder={$placeholder} onChange={(e) => {$setText(e.target.value);}} value={$text}>
    </Container>
  );
}