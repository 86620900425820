import styled from "styled-components"
import { mouv, color } from "../../../variable/styles";


interface Data {
    $active : boolean
}
export const Container = styled.div<Data>`
    box-sizing: border-box;
    flex : 1;
    display : flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;       
    white-space: nowrap;    
    text-overflow: ellipsis;
    ${({$active}) => $active && mouv};
    ${({$active}) => $active === false && 'opacity : 0.5'};
    &:hover {
        ${({$active}) => $active === true && `color : ${color.click};`}
    }
`;