import { BlockAgenda, BlockJour, BlockListMois, BlockListMoisTitre, BlockMois, BlockMoisJours, BlockMoiTitres, Container, NavBar, NavBarBtn } from './styles';
import { EventForPersonFront } from '../../../hook/persons/usePerson';
import { TextBtn } from '../../../composant/btn/textBtn/textBtn';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ifSameDay } from '../../../lib/date/ifSameDay';

interface Data {
  $events : EventForPersonFront[]
}
export function Agenda({$events} : Data) {
  const [annee, setAnnee] = useState<Date>(new Date(`${new Date().getFullYear()}/01/01`));
  const [listJour, setListJour] = useState<Date[][]>();
  const lettreJours = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
  const navigate = useNavigate();

  function genereteAllDay(myAnnee : Date) {
    let anneeDebut = new Date(myAnnee);
    let anneeFin = new Date(myAnnee.getFullYear() + 1, myAnnee.getMonth(), myAnnee.getDate());
    let listJour = [];
    let listMonth = [];
    for (let date = anneeDebut; date.getTime() < anneeFin.getTime(); date.setDate(date.getDate() + 1)) {
      if (listJour.length > 0 && date.getMonth() !== listJour[listJour.length -1].getMonth()) {
        listMonth.push(listJour);
        listJour = [];
      }
      for (let i = 1; i < date.getDay(); i++) {

      }
      listJour.push(new Date(date));
    }
    listMonth.push(listJour);
    return listMonth;
  }

  function ifEvent(date1 : Date) {


    const date = new Date(date1);
    const normalizedDate1 = new Date(`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T00:00:00.000Z`).getTime();
    return $events.find((event) =>  {
      const normalizedDeb = new Date(new Date(event.dateDebut).toISOString().split("T")[0]).getTime();
      const normalizedFin = new Date(new Date(event.dateFin).toISOString().split("T")[0]).getTime();    
    
      return ( normalizedDeb <= normalizedDate1 && normalizedDate1 <= normalizedFin )
    })
  } 

  useEffect(() => {
    if (annee) {
      setListJour(genereteAllDay(annee));
    }
  }, [annee])

  return (
    <Container>
      <NavBar>
        <NavBarBtn>
          <TextBtn $active={true} $onClick={() => {setAnnee(new Date(annee.getFullYear() - 1, annee.getMonth(), annee.getDate()))}} $text='< Année'/>
        </NavBarBtn>
        <NavBarBtn>
          <TextBtn $active={true} $onClick={() => {setAnnee(new Date(`${new Date().getFullYear()}/01/01`))}} $text="Aujourd'hui"/>
        </NavBarBtn>
        <NavBarBtn>
          <TextBtn $active={true} $onClick={() => {setAnnee(new Date(annee.getFullYear() + 1, annee.getMonth(), annee.getDate()))}} $text='Année >'/>
        </NavBarBtn>
      </NavBar>
      <BlockAgenda>
        <BlockListMoisTitre>{listJour && listJour[0][0].getFullYear()}</BlockListMoisTitre>
        <BlockListMois>
          {listJour?.map((moi, index) => {
            let jours : (Date | '')[] = [...moi];
            for (let i = 1; i < moi[0].getDay(); i++) {
              jours.unshift('');
            }
            return (
              <BlockMois key={index}>
                <BlockMoiTitres>{moi[0].toLocaleString('fr-FR', { month: 'long' })}</BlockMoiTitres>
                <BlockMoisJours>
                  {lettreJours.map((lettre, index) => {
                    return (
                      <BlockJour key={index+ moi[0].getTime()} $ifEvent={false} $isToday={false}>{lettre}</BlockJour>
                    );
                  })}
                  {jours.map((date, index) => {
                    if (date instanceof Date) {
                      let event = ifEvent(date);
                      return (
                        <BlockJour onClick={() => {event && navigate(`/event/Event?id_event=${event.id}`)}} key={date.getTime()} $isToday={ifSameDay(date, new Date())} $ifEvent={event !== undefined} >{date.getDate()}</BlockJour>
                      );
                    } else {
                      return (
                        <BlockJour key={index+ moi[0].getTime()+'A'} $ifEvent={false} $isToday={false}></BlockJour>
                      )
                    }
                  })}
                </BlockMoisJours>
              </BlockMois>
            );
          })}
        </BlockListMois>


      </BlockAgenda>
    </Container>
  );
}