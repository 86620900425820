import styled from "styled-components"
import { taille } from "../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    padding-left : 5px;
    padding-right : 5px;
    padding-bottom : 5px;
    flex : 1;
    width : ${taille.minBig};
    background-color : rgba(255,255,255,0.1);
    display : flex;
    flex-direction: column;
`;

interface Data {
}
export const BlockBtnAdd = styled.div<Data>`
    width : 50px;
    height : 50px;
    border-radius : 100%;
    margin : auto;
    margin-bottom : 20px;
    margin-top : 20px;
    display : flex;
    font-size : 40px;
`;

interface Data {
}
export const Titre = styled.div<Data>`
    font-size : 30px;
`;

interface Data {
}
export const BlockBtnAddPerson = styled.div<Data>`
    font-size : 20px;
    padding-bottom : 20px;
    display : flex;
`;

interface DataBlockBtnCategorieBoisson {
    $active : boolean
}
export const BlockBtnCategorieBoisson = styled.div<DataBlockBtnCategorieBoisson>`
    flex : 1;
    padding : 10px;
    border-radius : 5px;
    ${({$active}) => $active === true && `background-color : rgba(255,255,255,0.1)`};
`;

interface Data {
}
export const BlockParticipants = styled.div<Data>`
    overflow-y: auto;
    height : 80vh;
`;

interface Data {
}
export const BlockParticipant = styled.div<Data>`
    font-size : 20px;
    display : flex;
    justify-content: space-between;
    padding : 20px;
`;

interface DataBlockParticipantNom {
    $active : boolean
}
export const BlockParticipantNom = styled.div<DataBlockParticipantNom>`
    flex : 1;
    border-radius : 5px;
    ${({$active}) => $active === true && `background-color : rgba(255,255,255,0.1)`};
`;

interface Data {
}
export const BlockParticipantBtn = styled.div<Data>`
   width : 50px;
`;


interface Data {
}
export const BlockSearch = styled.div<Data>`
    height : 40px;
    display : flex;
    font-size : 25px;
    margin-bottom : 15px;
    

`;