import styled from "styled-components"
import { taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1;
    height : 100%;
    padding-left : 10px;
    padding-right : 10px;
    box-sizing: border-box;
`;

interface Data {
}
export const BlockNav = styled.div<Data>`
    box-sizing: border-box;
    display : flex;
    justify-content: space-between;
    font-size : 30px;
    color : rgba(255,255,255,0.5);
    
`;

interface DataBtnNav {
}
export const BtnNav = styled.div<DataBtnNav>`
    box-sizing: border-box;
    padding-left : 10px;
    padding-right : 10px;
`;

interface DataBtnNav {
}
export const BlockData = styled.div<DataBtnNav>`
   display : flex;
   flex-wrap: wrap;
   gap : 20px;
`;

