import { useContext } from 'react';
import { UpdateFunctionContextToken } from '../../context/tokenContext';
import { BlockBtnDroite, BlockBtnGauche, BtnMenu, Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';

interface Data {
}
export function Navbar() {
  const navigate = useNavigate();
  const location = useLocation(); // Hook pour connaître la page actuelle
  const {token, updateToken} = useContext(UpdateFunctionContextToken);

  return (
    <Container>
      <BlockBtnGauche>
        {token === undefined && location.pathname !== '/Accueil' && <BtnMenu onClick={() => {navigate('/Accueil')}}>Accueil</BtnMenu>}
        {token && location.pathname !== '/Profile' && <BtnMenu onClick={() => {navigate('/Profile')}}>Profile</BtnMenu>}
      </BlockBtnGauche>
      <BlockBtnDroite>
        {token && <BtnMenu onClick={() => {updateToken(undefined)}}>déconnexion</BtnMenu>}
      </BlockBtnDroite>
    </Container>
  );
}