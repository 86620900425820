import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './page/app/App';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
