import styled from "styled-components"
import { taille } from "../../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    padding-left : 5px;
    padding-right : 5px;
    padding-bottom : 5px;
    flex : 1;
    width : ${taille.minBig};
    background-color : rgba(255,255,255,0.1);
    color : rgba(255,255,255,0.5);
    display : flex;
    flex-direction: column;
`;

interface Data {
}
export const Titre = styled.div<Data>`
    font-size : 30px;
`;

interface Data {
}
export const BlockInfos = styled.div<Data>`
    overflow-y: auto;
    height : 80vh;
    display : flex;
    flex-direction: column;
    gap : 20px;
    padding-top : 20px;
`;

interface Data {
}
export const BlockInfo = styled.div<Data>`
    color : rgba(255,255,255,0.5);
    font-size : 20px;
    display : flex;
`;

interface Data {
}
export const BlockInfoTitre = styled.div<Data>`
    color : rgba(255,255,255,0.5);
    font-size : 20px;
    width : 80%;
    display : flex;
    align-items: center;
`;