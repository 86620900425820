import { useContext } from 'react';
import { BlockAgenda, BlockBoisson, BlockInfo, Container, Info, Titre } from './styles';
import { UpdateFunctionContextGlobale } from '../../context/contextGlobale';
import { log } from '../../lib/logger/config';
import { useRequestPerson } from '../../hook/persons/usePerson';
import { TableauEvent } from './tableauEvent/tableauEvent';
import { MenuBoisson } from '../../composant/menuBoisson/menuBoisson';
import { BlockMenu } from './blockMenue/blockMenue';
import { Agenda } from './agenda/agenda';

interface Data {
}
export function Profile() {
  const {person, requestNewEvent} = useRequestPerson()


  log.page('Page profile');
  if (!person)
    return <></>
  return (
    <Container>
      <BlockMenu/>
      <BlockInfo>
        <Titre>Profile</Titre>
        <Info>{person.email}</Info>
        <Info>{person.nom}</Info>
        <Info>{person.prenom}</Info>
      </BlockInfo>
      <BlockAgenda>
        <Agenda $events={person.events}/>

      </BlockAgenda>
    </Container>
  );
}