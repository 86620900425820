import styled from "styled-components"
import { mouv } from "../../../variable/styles";


interface Data {
}
export const Container = styled.textarea<Data>`
    all: unset; 
    box-sizing: border-box;
    display: inline-block;
    width : 100%;
    height : 100%;
    border-radius : 10px;
    padding : 20px;
    border-bottom : 1px solid rgba(255,255,255,0.5);


    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-weight : bold;
     opacity : 0.5;
    ${mouv}
    &:hover {
        opacity : 1;
    }
    &::placeholder {
        color : rgba(255,255,255,1);
        opacity: 0.5; 
        font-weight: normal;
    }
`;