import { Container, Icon } from './styles';

interface Data {
  $nameIcon : string;
  $onClick : () => void;
  $active : boolean
};
export function IconBtn({$nameIcon,  $onClick, $active} : Data) {
  return (
    <Container onClick={() => {$active && $onClick()}}  $active={$active}>
      <Icon className='material-symbols-outlined'>{$nameIcon}</Icon>
    </Container>
  );
}