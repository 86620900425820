import styled from "styled-components"
import { taille } from "../../../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    flex : 1;
    box-sizing: border-box;
    display : flex;
    flex-direction: column;
`;

interface Data {
}
export const Titre = styled.div<Data>`
    font-size : 50px;
    padding-bottom : 50px;
`;


interface Data {
}
export const BlockAddParticipant = styled.div<Data>`
    flex : 1;
    display : flex;
    flex-direction: column;
`;

interface Data {
}
export const BlockInput = styled.div<Data>`
    height : 50px;
    font-size : 20px;
`;

interface Data {
}
export const BlockBtn = styled.div<Data>`
    flex : 1;
`;