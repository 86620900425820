import { useContext, useState } from 'react';
import { IconBtn } from '../../../../composant/btn/iconBtn/iconBtn';
import { TextBtn } from '../../../../composant/btn/textBtn/textBtn';
import { BlockBoisson, BlockBoissonsTitre, BlockBoissonTitre, Container } from './styles';
import { UpdateFunctionContextEvent } from '../../../../context/eventContext';
import { UpdateFunctionContextBoisson } from '../../../../context/boissonContext';

interface Data {
  
}
export function ListBoisson({} : Data) {
  const {event, supBoisson} = useContext(UpdateFunctionContextEvent);

  if (!event) {
    return <></>
  }
  return (
    <Container>
      <BlockBoissonsTitre>Cocktail </BlockBoissonsTitre>
      {event.boissons.filter((boisson) => boisson.categorie === 'Cocktail').map((boisson, index) => {
        return (
          <BlockBoisson key={index}>
            <BlockBoissonTitre>{boisson.nom}</BlockBoissonTitre>
            <IconBtn $active={true} $nameIcon='delete' $onClick={() => {supBoisson(boisson.id)}}/>
          </BlockBoisson>
        )
      })}
      <BlockBoissonsTitre>shot </BlockBoissonsTitre>
      {event.boissons.filter((boisson) => boisson.categorie === 'shot').map((boisson, index) => {
        return (
          <BlockBoisson key={index}>
            <BlockBoissonTitre>{boisson.nom}</BlockBoissonTitre>
            <IconBtn $active={true} $nameIcon='delete' $onClick={() => {supBoisson(boisson.id)}}/>
          </BlockBoisson>
        )
      })}
      <BlockBoissonsTitre>Alcool leger </BlockBoissonsTitre>
      {event.boissons.filter((boisson) => boisson.categorie === 'Alcool_leger').map((boisson, index) => {
        return (
          <BlockBoisson key={index}>
            <BlockBoissonTitre>{boisson.nom}</BlockBoissonTitre>
            <IconBtn $active={true} $nameIcon='delete' $onClick={() => {supBoisson(boisson.id)}}/>
          </BlockBoisson>
        )
      })}
      <BlockBoissonsTitre>Soft </BlockBoissonsTitre>
      {event.boissons.filter((boisson) => boisson.categorie === 'Soft').map((boisson, index) => {
        return (
          <BlockBoisson key={index}>
            <BlockBoissonTitre>{boisson.nom}</BlockBoissonTitre>
            <IconBtn $active={true} $nameIcon='delete' $onClick={() => {supBoisson(boisson.id)}}/>
          </BlockBoisson>
        )
      })}
    </Container>
  );
}