import styled from "styled-components"
import { mouv, color} from "../../../variable/styles";

interface DataContainer {
    $active : boolean
}
export const Container = styled.div<DataContainer>`
    box-sizing: border-box;
    flex : 1;
    display : flex;
    justify-content: center;
    align-items: center;
    ${({$active}) => $active && mouv}
    ${({$active}) => $active === false && `opacity : 0.5;`}
    &:hover {
        border-radius : 100px;
        ${({$active}) => $active === true && `color : ${color.click}`}
    }
`;

interface DataIcon {
}
export const Icon = styled.span<DataIcon>`
    font-size: inherit;
`;