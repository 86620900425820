export const mouv = `
  transition : all 0.3s ease;
  cursor: pointer;
`

export const breakpoints = {
    xs: '480px',  // Extra small devices (mobile)
    sm: '768px',  // Small devices (tablets)
    md: '992px',  // Medium devices (desktops)
    lg: '1200px', // Large devices (large desktops)
    xl: '1600px'  // Extra large devices
  };

export const taille = {
  minSmall : `300px`,
  minBig : `350px`,
}

export const color = {
  click : 'rgba(255,255,255,1)',
  text : 'rgba(255,255,255,0.5)'
}
