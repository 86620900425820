import { useContext, useEffect, useState } from 'react';
import { BlockInfo, BlockInfos, BlockInfoTitre, Container, Titre } from './styles';
import { UpdateFunctionContextEvent } from '../../../../context/eventContext';
import { UpdateFunctionContextBoisson } from '../../../../context/boissonContext';
import { InputNumber } from '../../../../composant/input/inputNumber/inputNumber';
import { InputDate } from '../../../../composant/input/inputDate/inputDate';
import { TextBtn } from '../../../../composant/btn/textBtn/textBtn';
import { InputSmallText } from '../../../../composant/input/inputSmallText/inputSmallText';

interface Data {
}
export function MenuInfoTech({} : Data) {
  const {event, addPerson, updateInfoEvent, setEvent} = useContext(UpdateFunctionContextEvent);
  const {boissons, setCategorie} = useContext(UpdateFunctionContextBoisson);

  if (!boissons || !event) {
    return <></>
  }
  return (
    <Container>
      <Titre>Info technique</Titre>
      <BlockInfos>
        <BlockInfo>
          <BlockInfoTitre>Nom event</BlockInfoTitre>
          <InputSmallText $setText={(text:string) => {setEvent({...event,nom:text})}} $text={event.nom} $placeholder='Nom event'/>
        </BlockInfo>
        <BlockInfo>
          <BlockInfoTitre>Cocktail par personne par soire</BlockInfoTitre>
          <InputNumber $setText={(nb) => {nb >= 0 && setEvent({...event,nb_cocktail_pers_jour:nb})}} $number={event.nb_cocktail_pers_jour}/>
        </BlockInfo>
        <BlockInfo>
          <BlockInfoTitre>shot par personne par soire</BlockInfoTitre>
          <InputNumber $setText={(nb) => {nb >= 0 && setEvent({...event,nb_shot_pers_jour:nb})}} $number={event.nb_shot_pers_jour}/>
        </BlockInfo>
        <BlockInfo>
          <BlockInfoTitre>Alcool leger par personne par soire</BlockInfoTitre>
          <InputNumber $setText={(nb) => {nb >= 0 && setEvent({...event,nb_alcool_leger_pers_jour:nb})}} $number={event.nb_alcool_leger_pers_jour}/>
        </BlockInfo>
        <BlockInfo>
          <BlockInfoTitre>Softe par personne par soire</BlockInfoTitre>
          <InputNumber $setText={(nb) => {nb >= 0 && setEvent({...event,nb_soft_pers_jour:nb})}} $number={event.nb_soft_pers_jour}/>
        </BlockInfo>
        <BlockInfo>
          <BlockInfoTitre>Début</BlockInfoTitre>
          <InputDate $setDate={(date : number) => {setEvent({...event,dateDebut:date})}} $date={event.dateDebut} />
        </BlockInfo>
        <BlockInfo>
          <BlockInfoTitre>Fin</BlockInfoTitre>
          <InputDate $setDate={(date : number) => {setEvent({...event,dateFin:date})}} $date={event.dateFin} />
        </BlockInfo>
        <BlockInfo>
          <TextBtn $active={true} $text='sovgarder' $onClick={() => {updateInfoEvent()}}/>
        </BlockInfo>
      </BlockInfos>
    </Container>
  );
}