import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UpdateFunctionContextToken } from '../../context/tokenContext';
import { useSearchParams } from 'react-router-dom';

export type PersonFront = {
    id: number;
    nom: string;
    prenom: string;
    email: string;
    events: EventForPersonFront[];
}

export type EventForPersonFront = {
  id: number;
  nom: string;
  dateDebut:number;
  dateFin:number;
}

export function useRequestPerson() {

  const {token, updateToken} = useContext(UpdateFunctionContextToken);
  const [person, setPerson] = useState<PersonFront | undefined>();

  function requestPerson() {
    axios.get(`${process.env.REACT_APP_URL_BACK}/persons`, {
      headers: {
        Authorization: `Bearer ${token}`  // Envoi du token dans le header
      }
    })
    .then((res) => {
      setPerson({
        ...res.data, 
        events:res.data.events.map((event : any) => {return {...event, dateDebut:Number(event.dateDebut), dateFin:Number(event.dateFin)}}), 
      });
    }).catch((err) => {
      toast.error(err.response.data.message)
      updateToken(undefined)
    })
  }

  function requestNewEvent() {
    axios.post(`${process.env.REACT_APP_URL_BACK}/persons/event`, {}, {
      headers: {
        Authorization: `Bearer ${token}`  // Envoi du token dans le header
      }
    })
    .then((res) => {
      requestPerson();
    }).catch((err) => {
      toast.error(err.response.data.message)
      updateToken(undefined)
    })
  }

  useEffect(() => {
    if (token) {
      requestPerson();
    }
  }, [token]);

  return {person,requestPerson, requestNewEvent};
};