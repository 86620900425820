import React from 'react';
import { UpdateFunctionProviderGlobale } from './contextGlobale';
import { UpdateFunctionProviderToken } from './tokenContext';
import { UpdateFunctionProviderEvent } from './eventContext';
import { UpdateFunctionProviderBoisson } from './boissonContext';

interface Data {
  children: React.ReactNode;
  
};
export function ComposantContexts({children} : Data) {

  return (
    <div>
        <UpdateFunctionProviderGlobale>
          <UpdateFunctionProviderToken>
            <UpdateFunctionProviderEvent>
              <UpdateFunctionProviderBoisson>
                  {children}
              </UpdateFunctionProviderBoisson>
            </UpdateFunctionProviderEvent>
          </UpdateFunctionProviderToken>
        </UpdateFunctionProviderGlobale>
    </div>
  );
}
// const {token, updateToken} = useContext(UpdateFunctionContextToken);
