import { Container } from './styles';

interface Data {
  $number : number,
  $setText : (text : number) => void,
};
export function InputNumber({ $number, $setText} : Data) {

  return (
    <Container type='number' onChange={(e) => {$setText(parseInt(e.target.value))}} value={$number}>
    </Container>
  );
}