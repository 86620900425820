import { useContext, useEffect, useState } from 'react';
import { log } from '../../lib/logger/config';
import { BlockMenu } from './blockMenue/blockMenue';
import { Container } from './styles';
import { BlockInfo } from './blockInfo/blockInfo';

interface Data {
}
export function Event() {

  log.page('Page Event');
  return (
    <Container>
      <BlockMenu/>
      <BlockInfo/>
    </Container>
  );
}