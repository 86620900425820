import styled from "styled-components"
import { color, taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    max-height : 100vh;
    width : 100vw;
    min-width : ${taille.minBig};
    box-sizing: border-box;
    position : relative;
    background-color : #151C21;
    color : ${color.text};
    display : flex;
    flex-direction : column;
    overflow: hidden;
    z-index : 0;
`;


interface DataImgBackground {
}
export const ImgBackground = styled.img<DataImgBackground>`
    position: fixed;
    left : 50%;
    top : 50%;
    transform: translate(-50%, -50%);
    z-index : 1;
    height : 0px;
    width: 90vh;
    height: 90vh;
    object-fit: cover;
    opacity : 0.1;
`;

interface Data {
}
export const NavBarStyle = styled.div<Data>`
    height : 50px;
`;

interface Data {
}
export const PageStyle = styled.div<Data>`
    flex : 1;
    display : flex;
    overflow: hidden;
    height : 100%;
    z-index : 2;
`;
