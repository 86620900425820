import styled from "styled-components"
import { taille } from "../../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
    padding-left : 5px;
    padding-right : 5px;
    padding-bottom : 5px;
    flex : 1;
    width : ${taille.minSmall};
    background-color : rgba(255,255,255,0.1);
    color : rgba(255,255,255,0.5);
    display : flex;
    flex-direction: column;
`;

interface Data {
}
export const Titre = styled.div<Data>`
    font-size : 30px;
`;

interface Data {
}
export const BlockBtnAddPerson = styled.div<Data>`
    font-size : 20px;
    padding-bottom : 20px;
`;

interface Data {
}
export const BlockParticipants = styled.div<Data>`
    overflow-y: auto;
    height : 80vh;
`;

interface Data {
}
export const BlockParticipant = styled.div<Data>`
    font-size : 20px;
    display : flex;
    justify-content: space-between;
    padding : 20px;
`;

interface Data {
}
export const BlockParticipantNom = styled.div<Data>`
    flex : 1;
`;

interface Data {
}
export const BlockParticipantBtn = styled.div<Data>`
   width : 50px;
`;