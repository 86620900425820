import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export function useLoginGoogle() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [tokenTmp, setTokenTmp] = useState<string | undefined>();

  function requestLoginGoogle() {
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_API_GOOGLE_ID}&redirect_uri=${process.env.REACT_APP_API_GOOGLE_REDIRECTION_URI}&response_type=code&scope=openid%20email%20profile&access_type=offline&prompt=consent`;
  }

  function requestToken() {
    axios.get(`${process.env.REACT_APP_URL_BACK}/persons/login/`, {
      params : {
        code : code
      }
    })
      .then((res) => {
        setTokenTmp(res.data.token);
      }).catch((err) => {
        toast.error(err.response.data.message)
    })
  }



  useEffect(() => {
    if (code) {
      requestToken();
    }
  }, [code]);

  return {requestLoginGoogle, tokenTmp};
};

// https://event.serverbonnet.com/Accueil
// http://localhost:3000/Accueil