import { useContext, useEffect, useState } from 'react';
import { BlockBtnAddPerson, BlockParticipant, BlockParticipantBtn, BlockParticipantNom, BlockParticipants, Container, Titre } from './styles';
import { TextBtn } from '../../../../composant/btn/textBtn/textBtn';
import { IconBtn } from '../../../../composant/btn/iconBtn/iconBtn';
import { PopUp } from '../../../../composant/popUp/popUp';
import { PopUpNewPerson } from './popUpAddPerson/popUpAddPerson';
import { UpdateFunctionContextEvent } from '../../../../context/eventContext';

interface Data {
}
export function MenuPerson({} : Data) {
  const {event, addPerson, supPerson} = useContext(UpdateFunctionContextEvent);
  const [popUp, setPopUp] = useState<boolean>(false);

  if (!event) {
    return <></>
  }
  return (
    <Container>
      <PopUp $close={popUp} $setClose={() => {setPopUp(false)}}><PopUpNewPerson $setClose={async (text : string) => {return addPerson(text).then(() => {setPopUp(false)}).catch(() => {throw new Error()})}}/></PopUp>
      <Titre>Participants</Titre>
      <BlockBtnAddPerson>
        <TextBtn $text='Nouvelles personnes' $active={true} $onClick={() => {setPopUp(true)}}/>
      </BlockBtnAddPerson>
      <BlockParticipants>
        {event.persons.map((person : any, index : number) => {
          return (
            <BlockParticipant key={index}>
              <BlockParticipantNom>
                {`${person}`}
              </BlockParticipantNom>
              <BlockParticipantBtn>
                <IconBtn $nameIcon='Delete' $active={true} $onClick={() => {supPerson(person)}}/>
              </BlockParticipantBtn>
            </BlockParticipant>
          )
        })}
      </BlockParticipants>
    </Container>
  );
}