import { BlockIcon, Children, Container } from './styles';
import { IconBtn } from '../btn/iconBtn/iconBtn';
import { useEffect, useState } from 'react';

interface Data {
  children : React.ReactNode;
  $close : boolean;
  $setClose : () => void;
}
export function PopUp({children, $close, $setClose} : Data) {

  return (
    <Container $active={$close}>
      <BlockIcon>
        <IconBtn $nameIcon='Close' $active={true} $onClick={() => {$setClose()}}/>
      </BlockIcon>

      <Children>
        {children}
      </Children>
    </Container>
  );
}