import { useState } from 'react';
import { BlockAddParticipant, BlockBtn, BlockInput, Container, Titre } from './styles';
import { TextBtn } from '../../../../../composant/btn/textBtn/textBtn';
import { InputSmallText } from '../../../../../composant/input/inputSmallText/inputSmallText';

interface Data {
  $setClose : (text : string) => Promise<void>
}
export function PopUpNewPerson({$setClose} : Data) {
  const [participant, setParticipant] = useState('');
  
  return (
    <Container>
      <Titre>Ajouter un Participant</Titre>
      <BlockAddParticipant>
        <BlockInput>
          <InputSmallText $placeholder='Nom prenom' $text={participant} $setText={(text : string) => {setParticipant(text)}} />
        </BlockInput>
        <BlockBtn>
          <TextBtn $text='Je valide' $active={true} $onClick={() => {$setClose(participant).then(() => {setParticipant('')}).catch(() => {})}}/>
        </BlockBtn>
      </BlockAddParticipant>
    </Container>
  );
}