import styled from "styled-components"
import { mouv, taille } from "../../../variable/styles";



interface Data {
}
export const Container = styled.div<Data>`
   flex : 1;
   display : flex;
   flex-direction : column;
    box-sizing: border-box;
    padding : 20px;
`;

interface Data {
}
export const NavBar = styled.div<Data>`
   height : 50px;
   display : flex;
`;

interface Data {
}
export const NavBarBtn = styled.div<Data>`
    flex : 1;
`;

interface Data {
}
export const BlockAgenda = styled.div<Data>`
    box-sizing: border-box;
    flex : 1;
    display : flex;
    flex-direction : column;
    overflow-y: auto;
`;

interface Data {
}
export const BlockListMois = styled.div<Data>`
    box-sizing: border-box;
    flex : 1;
    display : flex;
    flex-wrap: wrap;
`;

interface Data {
}
export const BlockListMoisTitre = styled.div<Data>`
    box-sizing: border-box;
    height : 50px;
    margin : auto;
    font-size : 30px;
`;

interface Data {
}
export const BlockMois = styled.div<Data>`
    width : ${taille.minSmall};
    display : flex;
    flex-direction : column;
    margin : auto;
`;
interface Data {
}
export const BlockMoiTitres = styled.div<Data>`
    height : 20px;
`;

interface Data {
}
export const BlockMoisJours = styled.div<Data>`
    flex : 1;
    display : flex;
    flex-wrap: wrap;
    margin : auto;
`;

interface DataBlockJour {
    $isToday : boolean
    $ifEvent : boolean
}
export const BlockJour = styled.div<DataBlockJour>`
    width: calc(100% / 8); 
    height : 30px;
    border-radius : 50px;
    padding : 1px;
    ${({$isToday}) => $isToday && `border : 2px solid green`};
    ${({$ifEvent}) => $ifEvent && `background-color : red`};
    ${({$ifEvent}) => $ifEvent && mouv};
    display : flex;
    justify-content: center;
    align-items: center;
   
`;
