import { useContext, useState } from 'react';
import { IconBtn } from '../../../composant/btn/iconBtn/iconBtn';
import { TextBtn } from '../../../composant/btn/textBtn/textBtn';
import { Container, BlockNav, BtnNav, BlockContenue } from './styles';
import { MenuPerson } from './menuPerson/menuPerson';
import { MenuInfoTech } from './menuInfoTech/menuInfoTech';
import { MenuBoisson } from '../../../composant/menuBoisson/menuBoisson';
import { UpdateFunctionContextEvent } from '../../../context/eventContext';

interface Data {
}
export function BlockMenu({} : Data) {
  const [witchPage, setWitchPage] = useState('Personnes');
  const {event, addBoisson, supBoisson} = useContext(UpdateFunctionContextEvent);

  return (
    <Container>
      <BlockNav>
          <BtnNav $active={witchPage === 'Personnes'}>
            <TextBtn $active={true} $text='Personnes' $onClick={() => {setWitchPage('Personnes')}}/>
          </BtnNav>
          <BtnNav $active={witchPage === 'Boissons'}>
            <TextBtn $active={true} $text='Boissons' $onClick={() => {setWitchPage('Boissons')}}/>
          </BtnNav>
          <BtnNav $active={witchPage === 'Info tech'}>
            <TextBtn $active={true} $text='Info tech' $onClick={() => {setWitchPage('Info tech')}}/>
          </BtnNav>
      </BlockNav>
      {witchPage === 'Personnes' && <BlockContenue><MenuPerson/></BlockContenue>}
      {witchPage === 'Boissons' && <BlockContenue><MenuBoisson $event={event} $addBoisson={addBoisson} $supBoisson={supBoisson}/></BlockContenue>}
      {witchPage === 'Info tech' && <BlockContenue><MenuInfoTech/></BlockContenue>}
    </Container>
  );
}