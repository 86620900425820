import styled from "styled-components"
import { mouv } from "../../../variable/styles";


interface Data {
}
export const Container = styled.input<Data>`
    all: unset; 
    box-sizing: border-box;
    display: inline-block;
    width : 100%;
    height : 100%;
    border-radius : 10px;
    white-space: nowrap;
    font-weight : bold;
    border-bottom : 1px solid rgba(255,255,255,0.5);
    padding : 20px;
    opacity : 0.5;
    ${mouv}
    &:hover {
        opacity : 1;
    }
    &::placeholder {
        color : rgba(255,255,255,1);
        opacity: 0.7; 
        font-weight: normal;
    }
`;