import { useContext, useEffect, useState } from 'react';
import { IconBtn } from '../../../../composant/btn/iconBtn/iconBtn';
import { TextBtn } from '../../../../composant/btn/textBtn/textBtn';
import { BlockPrix, BlockPrixPers, BlockPrixTotale, BlockProduit, Container } from './styles';
import { UpdateFunctionContextEvent } from '../../../../context/eventContext';
import { UpdateFunctionContextBoisson } from '../../../../context/boissonContext';
import { ListBoisson } from '../listBoisson/listBoisson';

interface Data {
}
export function ListCourse({} : Data) {
  const {event} = useContext(UpdateFunctionContextEvent);

  function calculeListeCourse() {
    let listProduit = event?.boissons?.flatMap((boisson) => boisson.produits) || [];
    let mapCourse = new Map();
    let coutTotale = 0;

    listProduit.forEach((produit) => {
        const idProduit = produit.produit.id;
        
        if (mapCourse.has(idProduit)) {
            mapCourse.get(idProduit).quantite += produit.quantite;
        } else {
            mapCourse.set(idProduit, {
                quantite: produit.quantite,
                produit: produit.produit
            });
        }
    });

    let listCourse = Array.from(mapCourse.values()).map((produit) => {
      let quantite = Math.ceil(produit.quantite / produit.produit.unite);
      coutTotale += (produit.produit.prix * quantite);
      return {
        quantite: quantite,
        produit: produit.produit}
    });

    return {listCourse, coutTotale};
  }

  
  let info = calculeListeCourse();
  return (
    <Container>
      {info.listCourse.map((produit, index) => {
        return (
          <BlockProduit key={index}>{produit.produit.produit} {produit.quantite} * {produit.produit.unite}{produit.produit.mesure}</BlockProduit>
        )
      })}
      <BlockPrix>
        <BlockPrixTotale>Prix totale : {info.coutTotale} euros</BlockPrixTotale>
        <BlockPrixPers>Prix par personne : {Math.round(info.coutTotale/(event?.persons?.length || 1))} euros</BlockPrixPers>
      </BlockPrix>
    </Container>
  );
}